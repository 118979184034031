import React from 'react';
import '../styles/ESATIntroduction.css';

function PATIntroduction() {
  return (
    <div className="esat-introduction">
      <div className="intro-section">
        <h2>什么是PAT?</h2>
        <hr className="intro-divider" />
        <p>
        牛津PAT考试全称为 Physics Aptitude Test（物理能力测试），是牛津大学针对申请工程科学、材料科学、物理等本科或本硕专业的学生进行的入学测试。PAT是一项开放性考试，不仅限于报考牛津大学相关专业的考生，其他考生也可以参加。<br></br>
        考试时间：10月28日 <br></br>
        考试方式：自2024年起，PAT将全面改为机考模式。这次改革的主要目标是实现考试的全面机考化。 <br></br>
        根据官网的最新信息，尽管本次考试形式经历了一定调整，但考察内容依旧遵循原有的大纲，专注于A-level物理和数学知识（不包括进阶数学），并融入了一小部分额外的物理拓展内容。
        </p>
      </div>
    </div>
  );
}

export default PATIntroduction;
