import React, { useState, useEffect } from 'react';
import '../styles/ExamHeader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ConfirmHomeModal from './ConfirmHome';

function ExamHeader({ examType, examName }) {
  const [timeRemaining, setTimeRemaining] = useState(() => {
    const savedTime = sessionStorage.getItem('timeRemaining');
    return savedTime !== null ? Number(savedTime) : examName == "PAT" ? 7200: 2400;
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime > 0) {
          sessionStorage.setItem('timeRemaining', prevTime - 1);
          return prevTime - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleHomeClick = () => {
    if (examType === "timed") {
      setIsModalOpen(true);
    } else if (examName === "PAT") {
      navigate('/PatExam');
    } else {
      navigate('/EsatExam');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmNavigation = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  return (
    <>
      <header className="exam-header">
        <div className="header-content">
          <div className="left-content">
            <h1>{examName === "PAT" ? "PAT mock exam" : "ESAT mock exam"}</h1>
          </div>
          <div className="right-content">
            <button className="home-button" onClick={handleHomeClick}>
              <FontAwesomeIcon icon={faHome} />
            </button>
            {examType === "timed" &&
              <span className="time-remaining">
                <FontAwesomeIcon icon={faClock} /> Time {formatTime(timeRemaining)}
              </span>
            }
          </div>
        </div>
      </header>
      <ConfirmHomeModal 
        isOpen={isModalOpen} 
        onRequestClose={handleCloseModal} 
        onConfirm={handleConfirmNavigation} 
      />
    </>
  );
}

export default ExamHeader;
