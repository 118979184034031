import React, { createContext, useState, useEffect } from 'react';
import LoadingIndicator from '../components/Loading';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        // Load user from session storage if available
        const storedUser = sessionStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
        setLoading(false);
    }, []);

    const login = (userData) => {
        // Save user data to state and session storage
        const { password, ...userWithoutPassword } = userData;
        setUser(userWithoutPassword);
        sessionStorage.setItem('user', JSON.stringify(userWithoutPassword));
    };

    const logout = () => {
        // Remove user data from state and session storage
        setUser(null);
        sessionStorage.removeItem('user');
    };

    if (loading) {
        return <LoadingIndicator />; // Or a loading spinner
    }

    return (
        
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
