import React, { useState, useRef } from 'react';
import '../styles/FAQ.css';

function FAQ({questions, answers}) {
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRef = useRef([]);

    // const questions = [
    //     'What is the format of the ESAT exam?',
    //     'Can I use a calculator during the mock ESAT exam on the website?',
    //     'How is my score determined in the mock ESAT exam?',
    // ];

    // const answers = [
    //     'The ESAT (Engineering and Science Admissions Test) is composed of five modules, each lasting 40 minutes. Every module contains 27 multiple-choice questions. Candidates typically take Mathematics 1 and two other modules from the subjects of Biology, Chemistry, Physics, or Mathematics 2. The test is used for admissions purposes at Imperial College London and the University of Cambridge.',
    //     'No, calculators are not allowed during the real ESAT exam, and our mock exam website follows the same rule to ensure you simulate the real testing conditions as closely as possible. Make sure to practice solving the questions without a calculator.',
    //     'Your score is determined by the number of correct answers you provide, just like in the real ESAT exam. There are no pass/fail criteria, and your performance is based solely on the number of correct responses. After completing the mock exam, you will receive a percentage of correctness and a score, along with explanations for all questions.',
    // ];

    const toggleAnswer = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div className="faq-container">
            <h2 className="faq-title">常见问题</h2>
            <hr className="faq-divider" />
            <div className="faq-items">
                {questions.map((question, index) => (
                    <div
                        key={index}
                        className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleAnswer(index)}
                    >
                        <div className="faq-question">
                            <span className="faq-arrow">{activeIndex === index ? '▼' : '▶'}</span>
                            {question}
                        </div>
                        <div
                            ref={(el) => contentRef.current[index] = el}
                            className="faq-answer"
                            style={{
                                maxHeight: activeIndex === index ? `${contentRef.current[index]?.scrollHeight}px` : '0px',
                                padding: activeIndex === index ? '10px 0' : '0px 0',
                            }}
                        >
                            {answers[index]}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ;
