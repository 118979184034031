import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css';

const LoginPage = () => {
    const [isLogin, setIsLogin] = useState(true); // State to toggle between login and registration
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });

        if (response.ok) {
            const userData = await response.json();
            login(userData);
            navigate('/'); // Navigate to the home page or dashboard
        } else {
            setErrorMessage('Login failed. Please check your email and password.');
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (password.length < 8) {
            setErrorMessage('Password must be at least 8 characters long');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userName, email, password }),
        });

        if (response.ok) {
            const userData = await response.json();
            login(userData);
            navigate('/'); // Navigate to the home page or dashboard
            setErrorMessage('');
        } else {
            setErrorMessage('Registration failed. Please try again.');
        }
    };

    return (
        <div className="login-container">
            {isLogin ? (
                <form onSubmit={handleLogin}>
                    <h2>登陆</h2>
                    <input
                        type="email"
                        placeholder="用户邮箱"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="密码"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">登陆</button>
                    <p onClick={() => { setIsLogin(false); setErrorMessage(''); setEmail(""); setPassword("");}}>没有账号？立即注册</p>
                    {errorMessage && <div className="error-message-bar">{errorMessage}</div>}
                </form>
            ) : (
                <form onSubmit={handleRegister}>
                    <h2>注册</h2>
                    <input
                        type="text"
                        placeholder="用户名"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                    />
                    <input
                        type="email"
                        placeholder="用户邮箱"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="密码"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="确认密码"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button type="submit">注册</button>
                    <p onClick={() => { setIsLogin(true); setErrorMessage(''); setEmail(""); setPassword(""); setConfirmPassword(""); setUserName(""); }}>已有账户? 登陆</p>
                    {errorMessage && <div className="error-message-bar">{errorMessage}</div>}
                </form>
            )}
        </div>
    );
};

export default LoginPage;
