import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingIndicator from '../components/Loading';
import '../styles/PaymentStatus.css';

const PaymentStatusPage = () => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);

  // Get query parameters from URL
  const location = useLocation();
  const orderNumber = new URLSearchParams(location.search).get('out_trade_no');

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/payment/status?orderNumber=${orderNumber}`);
        const data = await response.json();
        setStatus(data.paymentStatus);
        if (data.paymentStatus === 'TRADE_SUCCESS') {
          const storedUser = sessionStorage.getItem('user');
          if (storedUser) {
            const user = JSON.parse(storedUser);
            const examName = data.examName;

            if (examName === 'ESAT') {
              user.esatStatus = "active";
              sessionStorage.setItem('user', JSON.stringify(user));
            } else if (examName === 'PAT') {
              user.patStatus = "active";
              sessionStorage.setItem('user', JSON.stringify(user));
          }
        }
      }
        
      } catch (error) {
        console.error('Failed to fetch payment status', error);
        setStatus('Error fetching status');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentStatus();
    // setStatus('Failed');
    // setLoading(false);
  }, [orderNumber]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="payment-status-container">
      <div className="payment-status-card">
        <h1>付款确认</h1>
        {status === 'TRADE_SUCCESS' ? (
          <>
            <p className="status-success">感谢您的购买！</p>
            <p>您的付款已成功处理。</p>
            <p>订单号：<strong>{orderNumber}</strong></p>
            <p className="formal-thanks">感谢您对我们的信任。如果您有任何问题，请随时<a href="/contact">联系我们的支持团队</a>。</p>
          </>
        ) : status === 'TRADE_CLOSED' ? (
          <>
            <p className="status-failed">抱歉，您的付款未能成功处理。</p>
            <p>订单号：<strong>{orderNumber}</strong></p>
            <p>请重试，或<a href="/contact">联系我们的支持团队</a>以获取帮助。</p>
          </>
        ) : (
          <>
            <p className="status-unknown">获取您的付款状态时出现问题。</p>
            <p>订单号：<strong>{orderNumber}</strong></p>
            <p>如果您需要进一步帮助，请联系我们的支持团队。</p>
          </>
        )}
      </div>

    </div>
  );
};

export default PaymentStatusPage;
