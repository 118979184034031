// import React, { useContext } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import '../styles/Header.css'; // Make sure to create and link this CSS file
// import logo from '../assets/lo.png';
// import { AuthContext } from '../context/AuthContext';

// function Header() {
//     const { user, logout } = useContext(AuthContext) || {}; // Ensure destructuring does not fail
//     const navigate = useNavigate();

//     const handleLogout = () => {
//         logout();
//         navigate('/'); // Redirect to home after logout
//     };

//     return (
//         <header className="header">
//             <div className="logo">
//                 <img src={logo} alt="Logo" className="logo-img" />
//             </div>
//             <div className="nav-container">
//                 <nav>
//                     <ul>
//                         <li><Link to="/">Home</Link></li>
//                         <li><Link to="/EsatExam">ESAT Exam</Link></li>
//                         <li><Link to="/PatExam">PAT Exam</Link></li>
//                     </ul>
//                 </nav>
//             </div>
//             <div className="user-actions">
//                 {user ? (
//                     <>
//                         <Link to="/profile">{user.name}</Link>
//                         <button onClick={handleLogout} className="logout-button">Logout</button>
//                     </>
//                 ) : (
//                     <Link to="/login" className="login-button">Login</Link> // Adjust the text as needed
//                 )}
//             </div>
//         </header>
//     );
// }

// export default Header;


import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Header.css'; // Ensure this CSS file is correctly linked
import logo from '../assets/lo.png';
import { AuthContext } from '../context/AuthContext';

function Header() {
    const { user, logout } = useContext(AuthContext) || {}; // Ensure destructuring does not fail
    const navigate = useNavigate();
    const [navActive, setNavActive] = useState(false); // State to toggle navigation

    const handleLogout = () => {
        logout();
        navigate('/'); // Redirect to home after logout
    };

    
    const toggleNav = () => {
        setNavActive(!navActive); // Toggle the navigation visibility
    };

    return (
        <header className={`header ${navActive ? 'nav-active' : ''}`}>
            <div className="logo">
                <img src={logo} alt="Logo" className="logo-img" />
            </div>
            <div className="nav-container">
                <nav>
                    <ul>
                        <li><Link to="/">首页</Link></li>
                        <li><Link to="/EsatExam">ESAT 考试</Link></li>
                        <li><Link to="/PatExam">PAT 考试</Link></li>
                    </ul>
                </nav>
            </div>
            <div className="user-actions">
                {user ? (
                    <>
                        <Link to="/profile">{user.name}</Link>
                        <button onClick={handleLogout} className="logout-button">登出</button>
                    </>
                ) : (
                    <Link to="/login" className="login-button">登陆</Link>
                )}
            </div>
        </header>
    );
}

export default Header;

