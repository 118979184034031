import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../styles/PracticeAndTest.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LoadingIndicator from '../components/Loading'; // Import LoadingIndicator

Modal.setAppElement('#root');

function PracticeAndTest() {
    const [selection, setSelection] = useState('');
    const [subSelection, setSubSelection] = useState('');
    const [topics, setTopics] = useState([]);
    const [subTopics, setSubTopics] = useState([]);
    const [currentTopic, setCurrentTopic] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [subModalIsOpen, setSubModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate(); // Initialize useNavigate

    const handleSelectionClick = (selection, subSelection) => {
        setSelection(selection);
        setSubSelection(subSelection);
        setModalIsOpen(true);

        if (selection === 'practice') {
            if (subSelection === 'math') {
                setTopics(['Algebra', 'Calculus', 'Geometry', 'Trigonometry', 
                'Counting and Probability', 'Logarithm', 'Coordinate System',
                 'Function and Graphs', 'Sequence']);
            } else if (subSelection === 'physics') {
                setTopics(['Mechanics', 'Electromagnetism', 'Thermodynamics']);
            }
        } else if (selection === 'exam') {
            if (subSelection === 'esat') {
                setTopics(['Mathematics1', 'Mathematics2', 'Physics']);
            } else if (subSelection === 'pat') {
                setTopics(['Mathematics', 'Physics']);
            }
        }
    };

    const handleTopicClick = (topic) => {
        setCurrentTopic(topic);
        if (selection === 'exam' && subSelection === 'esat' && topic === 'Mathematics1') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4', 'Mock Exam 5', 'Mock Exam 6', 'Mock Exam 7', 'Mock Exam 8', 'Mock Exam 9', 'Mock Exam 10']);
            setSubModalIsOpen(true);
        }
        else if (selection === 'exam' && subSelection === 'esat' && topic === 'Mathematics2') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4', 'Mock Exam 5', 'Mock Exam 6', 'Mock Exam 7']);
            setSubModalIsOpen(true);
        } 
        else if (selection === 'exam' && subSelection === 'esat' && topic === 'Physics') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4', 'Mock Exam 5', 'Mock Exam 6', 'Mock Exam 7', 'Mock Exam 8', 'Mock Exam 9', 'Mock Exam 10', 'Mock Exam 11', 'Mock Exam 12', 'Mock Exam 13', 'Mock Exam 14', 'Mock Exam 15', 'Mock Exam 16', 'Mock Exam 17', 'Mock Exam 18']);
            setSubModalIsOpen(true);
        }
        else {
            // Navigate to the topic directly if no further selection is needed
            setModalIsOpen(false);
            navigate(`/topic/${topic}`);
        }
    };

    const handleSubTopicClick = async (subTopic) => {
        setSubModalIsOpen(false);
        setLoading(true); // Set loading to true before fetching questions
        const questionData = await getExamQuestions(currentTopic, subTopic);
        setLoading(false); // Set loading to false after fetching questions
        sessionStorage.removeItem('currentQuestionIndex');
        sessionStorage.removeItem('selectedAnswers');
        sessionStorage.removeItem('timeRemaining');
        sessionStorage.removeItem('questions');
        sessionStorage.removeItem('questionStatuses');
        sessionStorage.removeItem('flaggedQuestions');
        navigate(`/exam`, { state: { topic: currentTopic, subTopic, questionInfo: questionData }});
    };

    const getExamQuestions = async (currentTopic, subTopic) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/question/getExamQuestions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', 
                body: JSON.stringify({ topic: currentTopic, subTopic }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch questions');
            }
            const questionData = await response.json();
            return questionData;
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="practice-page-container">
            {loading ? (
                <LoadingIndicator /> // Use LoadingIndicator component
            ) : (
                <>
                    <div className="card card-math" onClick={() => handleSelectionClick('practice', 'math')}>
                        <div className="card-header">
                            <span className="card-time">489 Questions</span>
                        </div>
                        <div className="card-body">
                            <h2>Practice Mathematics</h2>
                            <p>Improve your skills by practicing specific topics in Mathematics.</p>
                        </div>
                        <div className="card-footer">
                            <button className="start-button">Start Practicing</button>
                        </div>
                    </div>

                    <div className="card card-physics" onClick={() => handleSelectionClick('practice', 'physics')}>
                        <div className="card-header">
                            <span className="card-time">169 Questions</span>
                        </div>
                        <div className="card-body">
                            <h2>Practice Physics</h2>
                            <p>Improve your skills by practicing specific topics in Physics.</p>
                        </div>
                        <div className="card-footer">
                            <button className="start-button">Start Practicing</button>
                        </div>
                    </div>

                    <div className="card card-esat" onClick={() => handleSelectionClick('exam', 'esat')}>
                        <div className="card-header">
                            <span className="card-time"><i className='fas fa-clock'></i>40 minutes</span>
                        </div>
                        <div className="card-body">
                            <h2>Take ESAT Exam</h2>
                            <p>Test your knowledge by taking a full ESAT exam.</p>
                        </div>
                        <div className="card-footer">
                            <button className="start-button">Start the Test</button>
                        </div>
                    </div>

                    <div className="card card-pat" onClick={() => handleSelectionClick('exam', 'pat')}>
                        <div className="card-header">
                            <span className="card-time"><i className='fas fa-clock'></i>2 hours</span>
                        </div>
                        <div className="card-body">
                            <h2>Take PAT Exam</h2>
                            <p>Test your knowledge by taking a full PAT exam.</p>
                        </div>
                        <div className="card-footer">
                            <button className="start-button">Start the Test</button>
                        </div>
                    </div>

                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)}
                        contentLabel="Selection Modal"
                        className="Modal"
                        overlayClassName="Overlay"
                    >
                        {subSelection && topics.length > 0 && (
                            <>
                                <h3>Topics in {subSelection.charAt(0).toUpperCase() + subSelection.slice(1)}</h3>
                                <ul>
                                    {topics.map((topic, index) => (
                                        <li key={index} onClick={() => handleTopicClick(topic)}>
                                            {topic}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </Modal>

                    <Modal
                        isOpen={subModalIsOpen}
                        onRequestClose={() => setSubModalIsOpen(false)}
                        contentLabel="SubSelection Modal"
                        className="Modal"
                        overlayClassName="Overlay"
                    >
                        {currentTopic && subTopics.length > 0 && (
                            <>
                                <h3>{currentTopic} - Select a Mock Exam</h3>
                                <ul>
                                    {subTopics.map((subTopic, index) => (
                                        <li key={index} onClick={() => handleSubTopicClick(subTopic)}>
                                            {subTopic}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </Modal>
                </>
            )}
        </div>
    );
}

export default PracticeAndTest;
