import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../styles/ReviewPage.css';
import LoadingIndicator from '../components/Loading';

function ReviewPage() {

    const [data, setData] = useState({ mathProblems: [], physicsProblems: [],
         esatExams: [],
         patExams: [] });
    const [openSections, setOpenSections] = useState({
        math: false,
        physics: false,
        esat: false,
        pat: false,
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getExamQuestions = async (currentTopic, subTopic) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/question/getExamQuestions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', 
                body: JSON.stringify({ topic: currentTopic, subTopic }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch questions');
            }
            const questionData = await response.json();
            return questionData;
        } catch (err) {
            console.log(err);
        }
    };

    const handleItemClick = async (examClassData, historyData) => {
        const examClass = examClassData.examClass;
        const examType = examClassData.examType;
        setLoading(true);
        const questionData = await getExamQuestions(examType, examClass);
        // Initialize the selectedAnswers array
        let selectedAnswers = [];

        // Step 1: Sort the wrongQuestionAnswers by wrongQuestionId
        const wrongAnswers = historyData.wrongQuestionAnswers.sort((a, b) => a.wrongQuestionId.localeCompare(b.wrongQuestionId));

        // Step 2: Create a map of wrong answers for easy lookup
        const wrongAnswersMap = new Map();
        wrongAnswers.forEach(wrongAnswer => {
            wrongAnswersMap.set(wrongAnswer.wrongQuestionId, wrongAnswer.wrongAnswerId);
        });

        // Step 3: Iterate through the questions and construct the selectedAnswers array
        questionData.forEach(question => {
            const questionId = String(question.questionData.id);
            const correctAnswer = question.questionData.correctSelection;

            if (wrongAnswersMap.has(questionId)) {
                // If the question was answered incorrectly, store the wrong answer
                selectedAnswers.push(wrongAnswersMap.get(questionId));
            } else {
                // If the question was answered correctly, store the correct answer
                selectedAnswers.push(correctAnswer);
            }
        });

        // At this point, selectedAnswers contains the answers the user selected in order.
        navigate('/examReview', { state: { questions: questionData, selectedAnswers, topic: examType,  subTopic: examClass} });
        setLoading(false);
        
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('user'));
                const userEmail = user.email;

                const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/history/getExamHistory`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userEmail: userEmail })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();

                const mathProblems = [];
                const physicsProblems = [];
                const esatExams = [];
                const patExams = [];

                result.forEach((examResponse) => {
                    const { examClassData } = examResponse;
                    if (examClassData.examType === 'Mathematics1' || examClassData.examType === 'Mathematics2') {
                        esatExams.push(examResponse);
                    } else if (examClassData.examType === 'Physics') {
                        esatExams.push(examResponse);
                    } else if (examClassData.examType === 'ESAT') {
                        esatExams.push(examResponse);
                    } else if (examClassData.examType === 'PAT') {
                        patExams.push(examResponse);
                    }
                });
                setData({ mathProblems, physicsProblems, esatExams, patExams });

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); 


    const handleSectionToggle = (section) => {
        setOpenSections(prevState => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    return (
        <div className="review-page-container">
            {loading ? (
                <LoadingIndicator /> // Display loading indicator while loading
            ) : (
                <>
            <h1>Review Past Problems and Exams</h1>
            <div className="content-container">
                <div className="main-content">
                    <div className="navigation">
                        <button onClick={() => handleSectionToggle('math')}>Practice Mathematics</button>
                        <div className={`dropdown ${openSections.math ? 'open' : ''}`}>
                            <ul>
                                {data.mathProblems.map((examResponse) => {
                                    const { examClassData, historyData, questionCombineList } = examResponse;
                                    return (
                                        <li key={historyData.id} onClick={() => handleItemClick(examClassData, historyData)}>
                                                {historyData.examTime} - {examClassData.examClass} - {examClassData.examType}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <button onClick={() => handleSectionToggle('physics')}>Practice Physics</button>
                        <div className={`dropdown ${openSections.physics ? 'open' : ''}`}>
                            <ul>
                                {data.physicsProblems.map((examResponse) => {
                                    const { examClassData, historyData, questionCombineList } = examResponse;
                                    return (
                                        <li key={historyData.id} onClick={() => handleItemClick(examClassData, historyData)}>
                                                {historyData.examTime} - {examClassData.examClass} - {examClassData.examType}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <button onClick={() => handleSectionToggle('esat')}>ESAT Mock Exam</button>
                        <div className={`dropdown ${openSections.esat ? 'open' : ''}`}>
                            <ul>
                                {data.esatExams.map((examResponse) => {
                                    const { examClassData, historyData, questionCombineList } = examResponse;
                                    return (
                                        <li key={historyData.id} onClick={() => handleItemClick(examClassData, historyData)}>
                                                {historyData.examTime} - {examClassData.examClass} - {examClassData.examType}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <button onClick={() => handleSectionToggle('pat')}>PAT Mock Exam</button>
                        <div className={`dropdown ${openSections.pat ? 'open' : ''}`}>
                            <ul>
                                {data.patExams.map((examResponse) => {
                                    const { examClassData, historyData, questionCombineList } = examResponse;
                                    return (
                                        <li key={historyData.id} onClick={() => handleItemClick(examClassData, historyData)}>
                                                {historyData.examTime} - {examClassData.examClass} - {examClassData.examType}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </>
            )}
        </div>
    );
}

export default ReviewPage;
