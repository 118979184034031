import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../styles/PracticeAndTest.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LoadingIndicator from '../components/Loading';
import ExamCard from '../components/ExamCard';
import ConfirmResetModal from '../components/ConfirmResetModal';
import ESATIntroduction from '../components/ESATIntroduction'; 
import ContactInfo from '../components/ContactInfo';
import FAQ from '../components/FAQ';
import RemindPurchaseModal from '../components/RemindPurchaseModal';

Modal.setAppElement('#root');

function ESATExam() {
    const [topics, setTopics] = useState([]);
    const [subTopics, setSubTopics] = useState([]);
    const [currentTopic, setCurrentTopic] = useState('');
    const [selectedSubTopic, setSelectedSubTopic] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [subModalIsOpen, setSubModalIsOpen] = useState(false);
    const [examTypeModalIsOpen, setExamTypeModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [remindPurchaseModalIsOpen, setRemindPurchaseModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [examMath1Status, setExamMath1Status] = useState({});
    const [examMath2Status, setExamMath2Status] = useState({});
    const [examPhysicsStatus, setExamPhysicsStatus] = useState({});
    const [examChemistryStatus, setExamChemistryStatus] = useState({});
    const [examBiologyStatus, setExamBiologyStatus] = useState({});
    const [resetTopic, setResetTopic] = useState('');
    const [resetSubTopic, setResetSubTopic] = useState('');
    const [deleting, setDeleting] = useState(false);
    const [esatStatus, setEsatStatus] = useState('');
    const navigate = useNavigate();

    const faqQuestions = [
        'ESAT的考试形式是什么样的?',
        '考试的时候可以用计算器吗?',
        '我的成绩是如何决定的',
        '这个ESAT模考网站如何帮助我备考?'
    ];

    const faqAnswers = [
        'ESAT（工程与科学入学考试）由五个模块组成，每个模块考试时间为40分钟，每个模块包含27道选择题。考生通常需要参加数学1模块，以及生物、化学、物理或数学2中的两个模块。',
        '不，ESAT正式考试中不允许使用计算器，我们的ESAT模考系统也遵循这一规定，以确保你能够尽可能贴近真实考试环境。请不要使用计算器解题！',
        '模考系统的评分方式与ESAT正式考试相同，完全根据你回答正确的题目数量和考试难度来确定成绩。考试没有通过或不通过的标准，表现完全取决于正确回答的数量。完成模考后，你将获得正确率百分比和成绩，并查看所有题目的解析。',
        '我们的模考网站旨在高度还原ESAT正式考试的体验！通过模拟考试环境并复习错题，你可以在正式考试前建立信心，提高解题能力。如有任何关于平台或者备考的问题，欢迎联系我们，我们会尽力帮助你取得一个好成绩！'
    ];

    useEffect(() => {
        const fetchStatus = async () => {
            setLoading(true);
            await fetchExamStatus();
            const user = JSON.parse(sessionStorage.getItem('user'));
            if (user) {
                setEsatStatus(user.esatStatus);
            }
            setLoading(false);
        };
        fetchStatus();
    }, []);

    useEffect(() => {
        // Check if any modal is open
        if (modalIsOpen || subModalIsOpen || examTypeModalIsOpen || remindPurchaseModalIsOpen) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling
        }

        // Cleanup function to reset scroll on component unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalIsOpen, subModalIsOpen, examTypeModalIsOpen, remindPurchaseModalIsOpen]);

    const fetchExamStatus = async () => {
        try {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const userEmail = user.email;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/history/getExamHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ userEmail: userEmail }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch exam status');
            }
            const statusData = await response.json();
            const newMath1ExamStatus = {};
            const newMath2ExamStatus = {};
            const newPhysicsExamStatus = {};
            const newChemistryExamStatus = {};
            const newBiologyExamStatus = {};
            statusData.forEach(item => {
                if (item.examClassData.examType.startsWith('Mathematics1')) {
                    newMath1ExamStatus[item.examClassData.examClass] = item;
                } else if (item.examClassData.examType.startsWith('Mathematics2')) {
                    newMath2ExamStatus[item.examClassData.examClass] = item;
                } else if (item.examClassData.examType.startsWith('Physics')) {
                    newPhysicsExamStatus[item.examClassData.examClass] = item;
                } else if (item.examClassData.examType.startsWith('Chemistry')) {
                    newChemistryExamStatus[item.examClassData.examClass] = item;
                } else if (item.examClassData.examType.startsWith('Biology')) {
                    newBiologyExamStatus[item.examClassData.examClass] = item;
                }
            });
            setExamMath1Status(newMath1ExamStatus);
            setExamMath2Status(newMath2ExamStatus);
            setExamPhysicsStatus(newPhysicsExamStatus);
            setExamChemistryStatus(newChemistryExamStatus);
            setExamBiologyStatus(newBiologyExamStatus);

        } catch (err) {
            console.log(err);
        }
    };

    const handleReviewClick = async (e, subTopic) => {
        e.stopPropagation();
        let examResponse;
        if (currentTopic === 'Mathematics1') {
            examResponse = examMath1Status[subTopic];
        } else if (currentTopic === 'Mathematics2') {
            examResponse = examMath2Status[subTopic];
        } else if (currentTopic === 'Physics') {
            examResponse = examPhysicsStatus[subTopic];
        } else if (currentTopic === 'Chemistry') {
            examResponse = examChemistryStatus[subTopic];
        } else if (currentTopic === 'Biology') {
            examResponse = examBiologyStatus[subTopic];
        }
        if (examResponse) {
            const { examClassData, historyData } = examResponse;
            const examClass = examClassData.examClass;
            const examType = examClassData.examType;
            setLoading(true);
            const questionData = await getExamQuestions(examType, examClass);

            let selectedAnswers = [];

            const wrongAnswers = historyData.wrongQuestionAnswers.sort((a, b) => a.wrongQuestionId.localeCompare(b.wrongQuestionId));

            const wrongAnswersMap = new Map();
            wrongAnswers.forEach(wrongAnswer => {
                wrongAnswersMap.set(wrongAnswer.wrongQuestionId, wrongAnswer.wrongAnswerId);
            });

            questionData.forEach(question => {
                const questionId = String(question.questionData.id);
                const correctAnswer = question.questionData.correctSelection;

                if (wrongAnswersMap.has(questionId)) {
                    selectedAnswers.push(wrongAnswersMap.get(questionId));
                } else {
                    selectedAnswers.push(correctAnswer);
                }
            });

            navigate('/examReview', { state: { questions: questionData, selectedAnswers, topic: examType, subTopic: examClass } });
            setLoading(false);
        }
    };

    const handleResetClick = (e, topic, subTopic) => {
        e.stopPropagation();
        setResetTopic(topic);
        setResetSubTopic(subTopic);
        setConfirmModalIsOpen(true);
    };

    const confirmReset = async () => {
        setDeleting(true);

        const resetExamHistory = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('user'));
                const userEmail = user.email;
                await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/history/deleteExamHistory`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ userEmail, topic: resetTopic, subTopic: resetSubTopic }),
                });
            } catch (err) {
                console.log('Failed to reset exam history:', err);
            }
        };
    
        await resetExamHistory();

        setExamMath1Status((prevStatus) => {
            if (resetTopic === 'Mathematics1') {
                const updatedStatus = { ...prevStatus };
                delete updatedStatus[resetSubTopic];
                return updatedStatus;
            }
            return prevStatus;
        });
    
        setExamMath2Status((prevStatus) => {
            if (resetTopic === 'Mathematics2') {
                const updatedStatus = { ...prevStatus };
                delete updatedStatus[resetSubTopic];
                return updatedStatus;
            }
            return prevStatus;
        });
    
        setExamPhysicsStatus((prevStatus) => {
            if (resetTopic === 'Physics') {
                const updatedStatus = { ...prevStatus };
                delete updatedStatus[resetSubTopic];
                return updatedStatus;
            }
            return prevStatus;
        });

        setExamChemistryStatus((prevStatus) => {
            if (resetTopic === 'Chemistry') {
                const updatedStatus = { ...prevStatus };
                delete updatedStatus[resetSubTopic];
                return updatedStatus;
            }
            return prevStatus;
        });

        setExamBiologyStatus((prevStatus) => {
            if (resetTopic === 'Biology') {
                const updatedStatus = { ...prevStatus };
                delete updatedStatus[resetSubTopic];
                return updatedStatus;
            }
            return prevStatus;
        });

        setDeleting(false);
        setConfirmModalIsOpen(false);
    };

    const handleSelectionClick = () => {
        setTopics(['Mathematics1', 'Mathematics2', 'Physics', 'Chemistry', 'Biology']);
        setModalIsOpen(true);
    };

    const handleTopicClick = (topic) => {
        setCurrentTopic(topic);
        if (topic === 'Mathematics1') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4', 'Mock Exam 5', 'Mock Exam 6', 'Mock Exam 7', 'Mock Exam 8', 'Mock Exam 9', 'Mock Exam 10', 'Mock Exam 11', 'Mock Exam 12']);
            setSubModalIsOpen(true);
            setModalIsOpen(false);
        }
        else if (topic === 'Mathematics2') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4', 'Mock Exam 5', 'Mock Exam 6']);
            setSubModalIsOpen(true);
            setModalIsOpen(false);
        } 
        else if (topic === 'Physics') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4', 'Mock Exam 5', 'Mock Exam 6', 'Mock Exam 7', 'Mock Exam 8', 'Mock Exam 9', 'Mock Exam 10', 'Mock Exam 11', 'Mock Exam 12', 'Mock Exam 13', 'Mock Exam 14', 'Mock Exam 15', 'Mock Exam 16', 'Mock Exam 17']);
            setSubModalIsOpen(true);
            setModalIsOpen(false);
        }
        else if (topic === 'Chemistry') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4', 'Mock Exam 5', 'Mock Exam 6', 'Mock Exam 7']);
            setSubModalIsOpen(true);
            setModalIsOpen(false);
        } else if (topic === 'Biology') {
            setSubTopics(['Mock Exam 1', 'Mock Exam 2', 'Mock Exam 3', 'Mock Exam 4']);
            setSubModalIsOpen(true);
            setModalIsOpen(false);
        }
    };

    const handleSubTopicClick = async (subTopic) => {
        if (esatStatus !== 'active' && (subTopic !== "Mock Exam 1")){
            setRemindPurchaseModalIsOpen(true);
        } else {
            setSelectedSubTopic(subTopic);
            setModalIsOpen(false);
            setExamTypeModalIsOpen(true); // Open the modal for selecting exam type     
        }
    };

    const handleExamTypeSelection = async (examType) => {
        setExamTypeModalIsOpen(false);
        setLoading(true);

        const questionData = await getExamQuestions(currentTopic, selectedSubTopic);
        
        setLoading(false);
        sessionStorage.removeItem('currentQuestionIndex');
        sessionStorage.removeItem('selectedAnswers');
        sessionStorage.removeItem('timeRemaining');
        sessionStorage.removeItem('questions');
        sessionStorage.removeItem('questionStatuses');
        sessionStorage.removeItem('flaggedQuestions');
        
        navigate(`/exam`, { state: { topic: currentTopic, subTopic: selectedSubTopic, examType, questionInfo: questionData } });
    };

    const getExamQuestions = async (currentTopic, subTopic) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/question/getExamQuestions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', 
                body: JSON.stringify({ topic: currentTopic, subTopic }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch questions');
            }
            const questionData = await response.json();
            return questionData;
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="practice-page-container">
            {loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <ESATIntroduction />
                    <ExamCard
                        onClick={handleSelectionClick}
                        cardClass="card-esat"
                        cardTime="40 分钟"
                        cardTitle="参加 ESAT 模拟考试"
                        cardDescription="通过参加完整的 ESAT 考试来测试你的知识水平。"
                    />

                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)}
                        contentLabel="Selection Modal"
                        className="Modal"
                        overlayClassName="Overlay"
                    >
                        <>
                            <h3>选择ESAT考试模块</h3>
                            <ul className="centered-list">
                                {topics.map((topic, index) => (
                                    <li key={index} onClick={() => handleTopicClick(topic)}>
                                        {topic}
                                    </li> 

                                ))}
                            </ul>
                        </>
                    </Modal>

                    <Modal
                        isOpen={subModalIsOpen}
                        onRequestClose={() => setSubModalIsOpen(false)}
                        contentLabel="SubSelection Modal"
                        className="Modal"
                        overlayClassName="Overlay"
                    >
                        <>
                            <h3>{currentTopic} - 选择模考试卷</h3>
                            <ul className="exam-list">
                                {subTopics.map((subTopic, index) => {
                                    let currentStatus;

                                    if (currentTopic === 'Mathematics1') {
                                        currentStatus = examMath1Status[subTopic];
                                    } else if (currentTopic === 'Mathematics2') {
                                        currentStatus = examMath2Status[subTopic];
                                    } else if (currentTopic === 'Physics') {
                                        currentStatus = examPhysicsStatus[subTopic];
                                    } else if (currentTopic === 'Chemistry') {
                                        currentStatus = examChemistryStatus[subTopic];
                                    } else if (currentTopic === 'Biology') {
                                        currentStatus = examBiologyStatus[subTopic];
                                    }

                                    return (
                                        <li 
                                            key={index} 
                                            onClick={() => handleSubTopicClick(subTopic)}
                                            className={`exam-item ${currentStatus ? 'done' : 'undone'}`}
                                        >
                                            <span className="exam-name">{subTopic}</span>
                                            <div className="exam-status-container">
                                                {esatStatus !== 'active' && subTopic !== "Mock Exam 1" && (
                                                    <div className="lock-message">
                                                        <span className="unlock-text">解锁访问</span>
                                                        <i className="fas fa-lock"></i>
                                                    </div>
                                                )}
                                                {currentStatus && (
                                                    <>
                                                        <button 
                                                            className="review-button"
                                                            onClick={(e) => handleReviewClick(e, subTopic)}
                                                        >
                                                            考试回顾
                                                        </button>
                                                        <button 
                                                            className="reset-button"
                                                            onClick={(e) => handleResetClick(e, currentTopic, subTopic)}
                                                        >
                                                            考试重置
                                                        </button>
                                                    </>
                                                )}
                                                <span className={`exam-status ${currentStatus ? 'done' : 'undone'}`}>
                                                    {currentStatus ? '完成' : '未完成'}
                                                </span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    </Modal>

                    <Modal
                        isOpen={examTypeModalIsOpen}
                        onRequestClose={() => setExamTypeModalIsOpen(false)}
                        contentLabel="Exam Type Selection Modal"
                        className="Modal exam-type"
                        overlayClassName="Overlay"
                    >
                        <>
                            <h3>选择考试形式</h3>
                            <div className="confirm-modal-buttons">
                                <button className="confirm-button" onClick={() => handleExamTypeSelection('timed')}>
                                    计时考试
                                </button>
                                <button className="confirm-button" onClick={() => handleExamTypeSelection('practice')}>
                                    练习考试
                                </button>
                            </div>
                        </>
                    </Modal>

                    <ConfirmResetModal
                        isOpen={confirmModalIsOpen}
                        onRequestClose={() => setConfirmModalIsOpen(false)}
                        resetItem={resetSubTopic}
                        confirmReset={confirmReset}
                        deleting={deleting}
                    />

                    <RemindPurchaseModal
                        isOpen={remindPurchaseModalIsOpen}
                        onRequestClose={() => setRemindPurchaseModalIsOpen(false)}
                        examName={"ESAT"}
                    />
                    
                    <FAQ questions={faqQuestions} answers={faqAnswers} />
                    <ContactInfo />
                </>
            )}
        </div>
    );
}

export default ESATExam;
