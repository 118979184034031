import React from 'react';
import Modal from 'react-modal';

const ConfirmHomeModal = ({ isOpen, onRequestClose, onConfirm }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Confirm Navigation"
            className="Modal confirm-modal"
            overlayClassName="Overlay"
        >
            <>
                <h3>确认导航</h3>
                <p>您确定要返回主页吗？您的进度可能会丢失。</p>
                <div className="confirm-modal-buttons">
                    <button className="confirm-button" onClick={onConfirm}>
                        是的，返回主页
                    </button>
                    <button className="cancel-button" onClick={onRequestClose}>
                        取消
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default ConfirmHomeModal;
