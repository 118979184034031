// import React, { useReducer, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import '../styles/ServiceInfoPage.css'; // Create this file for styling
// import LoadingIndicator from '../components/Loading';

// function ServiceInfoPage() {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const { examName } = location.state || {}; 
//     const [examNumbers, setExamNumbers] = useState(() => {
//         return examName === "ESAT" ? 51 : 16;
//     });
//     const [isLoading, setIsLoading] = useState(false);



//     const handlePurchase = async () => {
//         setIsLoading(true); 
//         const user = JSON.parse(sessionStorage.getItem('user'));
//         const email = user.email;
//         const paymentRequest = {
//             email: email,
//             examType: examName
//         };
    
//         try {
//             const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/payment/createPayment`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(paymentRequest),
//             });
    
//             if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`);
//             }
    
//             const data = await response.text();
            
//             // Assuming the response contains redirection URL from Alipay
//             const parser = new DOMParser();
//             const doc = parser.parseFromString(data, 'text/html');
//             const form = doc.querySelector('form');
//             document.body.appendChild(form);
//             form.submit();
//             // window.location.href = data;
    
//         } catch (error) {
//             console.error('Error processing payment:', error);
//             alert('There was an error processing the payment. Please try again.');
//         }
//     };
    
//     // if (isLoading) {
//     //     return (
//     //         <div className="service-info-container">
//     //             <h1>处理中...</h1>
//     //             <p>正在为您跳转至支付页面，请稍候...</p>
//     //         </div>
//     //     );
//     // }

//     return (
//         <div className="service-info-container">
//             {isLoading ? (
//                 <LoadingIndicator />
//             ) :(
//             <>
//             <h1>解锁高级模考</h1>
//             <p>通过升级订阅，您将获得以下权益：</p>
//             <ul className="serviceList">
//                 <li>无限访问所有 {examNumbers} 套不同难度的模考，帮助您为 {examName} 精进技能！</li>
//                 <li>即时访问1000+道题目，涵盖历年真题、牛剑考试以及其他高水平数学和物理竞赛。</li>
//                 <li>每道题目均提供详细的分步解析。</li>
//                 <li>每次模考都会获得标准化分数，帮助您评估表现。</li>
//             </ul>

//             <div className="pricing-section">
//                 <h2>订阅价格</h2>
//                 <p>仅需 <strong>¥ 299</strong> 即可永久获取全部题目和解析！</p>
//             </div>

//             <button className="purchase-button" onClick={handlePurchase}>
//                 立即购买
//             </button>

//             <button className="back-button" onClick={() => navigate("/")}>
//                 返回
//             </button>
//             </>
//             )}
//         </div>
//     );
// }

// export default ServiceInfoPage;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/ServiceInfoPage.css'; // Ensure this file exists for styling
import LoadingIndicator from '../components/Loading';

function ServiceInfoPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { examName } = location.state || {}; 
    const [examNumbers, setExamNumbers] = useState(() => {
        return examName === "ESAT" ? 51 : 16;
    });
    const [isLoading, setIsLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState('');

    // Set the target end time to 9/17 at 00:00 Beijing time (UTC+8)
    const targetEndTime = new Date('2024-09-17T00:00:00+08:00'); // Explicitly set the Beijing time

    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date();
            const timeLeft = targetEndTime - now;

            if (timeLeft <= 0) {
                setTimeRemaining('00天 00小时 00分 00秒');
                return;
            }

            const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            setTimeRemaining(`${days}天 ${hours}小时 ${minutes}分 ${seconds}秒`);
        };

        updateCountdown(); // Initialize the countdown immediately
        const countdownInterval = setInterval(updateCountdown, 1000);

        return () => clearInterval(countdownInterval); // Cleanup on component unmount
    }, [targetEndTime]);

    return (
        <div className="service-info-container">
            {isLoading ? (
                <LoadingIndicator />
            ) : (
            <>
            <h1>解锁高级模考</h1>
            <p>通过升级订阅，您将获得以下权益：</p>
            <ul className="serviceList">
                <li>无限访问所有 {examNumbers} 套不同难度的模考，帮助您为 {examName} 精进技能！</li>
                <li>即时访问1000+道题目，涵盖历年真题、牛剑考试以及其他高水平数学和物理竞赛。</li>
                <li>每道题目均提供详细的分步解析。</li>
                <li>每次模考都会获得标准化分数，帮助您评估表现。</li>
            </ul>

            <div className="pricing-section">
                <h2>订阅价格</h2>
                <p className="original-price">原价 <s>¥ 699</s></p>
                <p>限时优惠 <strong>¥ 299</strong>！</p>
                <p className="discount-end">截止时间: 2024年9月17日</p>
                <p className="countdown-timer">倒计时: {timeRemaining}</p>
            </div>

            <button className="purchase-button" onClick={ () => navigate('/paymentPage', { state: { examName: examName} })}>
                立即购买
            </button>

            <button className="back-button" onClick={() => navigate("/")}>
                返回
            </button>
            </>
            )}
        </div>
    );
}

export default ServiceInfoPage;
