// ConfirmResetModal.js
import React from 'react';
import Modal from 'react-modal';

const ConfirmResetModal = ({ isOpen, onRequestClose, resetItem, confirmReset, deleting }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Confirm Reset Modal"
        className="Modal confirm-modal"
        overlayClassName="Overlay"
    >
        <>
            <h3>确认重置</h3>
            <p>您确定要重置 {resetItem} 的进度吗？</p>
            <div className="confirm-modal-buttons">
                <button className="confirm-button" onClick={confirmReset} disabled={deleting}>
                    {deleting ? (
                        <div className="small-loading-spinner"></div>
                    ) : (
                        "是的，重置"
                    )}
                </button>
                <button className="cancel-button" onClick={onRequestClose} disabled={deleting}>
                    取消
                </button>
            </div>
        </>
    </Modal>
);

export default ConfirmResetModal;
