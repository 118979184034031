// ExamCard.js
import React from 'react';

const ExamCard = ({ onClick, cardClass, cardTitle, cardTime, cardDescription }) => (
    <div className={`card ${cardClass}`} onClick={onClick}>
        <div className="card-header">
            <span className="card-time"><i className='fas fa-clock'></i>{cardTime}</span>
        </div>
        <div className="card-body">
            <h2>{cardTitle}</h2>
            <p>{cardDescription}</p>
        </div>
        <div className="card-footer">
            <button className="start-button">开始模考</button>
        </div>
    </div>
);

export default ExamCard;
