import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../styles/PracticeAndTest.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LoadingIndicator from '../components/Loading';
import ExamCard from '../components/ExamCard';
import ConfirmResetModal from '../components/ConfirmResetModal';
import PATIntroduction from '../components/PATIntroduction';
import ContactInfo from '../components/ContactInfo';
import FAQ from '../components/FAQ';
import RemindPurchaseModal from '../components/RemindPurchaseModal';

Modal.setAppElement('#root');

function PATExam() {
    const [subTopics, setSubTopics] = useState([]);
    const [currentSubTopic, setCurrentSubTopic] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [examTypeModalIsOpen, setExamTypeModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [examStatus, setExamStatus] = useState({});
    const [resetSubTopic, setResetSubTopic] = useState('');
    const [deleting, setDeleting] = useState(false);
    const [patStatus, setPatStatus] = useState('');
    const [remindPurchaseModalIsOpen, setRemindPurchaseModalIsOpen] = useState(false);
    const navigate = useNavigate();

    const faqQeustions = [
        'PAT的考试形式是什么样的?',
        '考试时我能用计算器吗?',
        'PAT会考什么内容?',
        '这个模考平台如何帮助我备考?'
    ]

    const faqAnswers = [
        '考试时长维持2小时（120分钟）不变。机考版PAT取消了解答题，全部改为选择题，共40道题，总分100分。',
        'PAT考试中不允许使用实体计算器。但从2023年起，考试界面中将内置一个数字计算器，考生必须使用这个数字计算器进行考试。',
        'PAT考试涵盖广泛的物理和数学知识，包括力学、波、电学、微积分和自然世界。考试大纲与AS水平的数学和物理内容密切相关，但部分题目可能需要自学补充。我们的模考平台提供了各个相关主题的多样化题目，帮助你高效练习。这些题目严格按照PAT大纲设置，让你放心备考。',
        '我们的模考网站旨在高度还原PAT正式考试的体验！通过模拟考试环境并复习错题，你可以在正式考试前建立信心，提高解题能力。如有任何关于平台或者备考的问题，欢迎联系我们，我们会尽力帮助你取得一个好成绩！'
    ]

    useEffect(() => {
        const fetchStatus = async () => {
            setLoading(true);
            await fetchExamStatus();
            const user = JSON.parse(sessionStorage.getItem('user'));
            if (user) {
                setPatStatus(user.patStatus);
            }
            setLoading(false);
        };
        fetchStatus();
    }, []);

    useEffect(() => {
        // Disable scrolling when the modal is open
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup function to reset scroll
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalIsOpen]);

    const fetchExamStatus = async () => {
        try {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const userEmail = user.email;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/history/getExamHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ userEmail: userEmail }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch exam status');
            }
            const statusData = await response.json();
            
            const newExamStatus = {};
            statusData.forEach(item => {
                if (item.examClassData.examType === 'PAT') {
                    newExamStatus[item.examClassData.examClass] = item;
                }
            });
            setExamStatus(newExamStatus);
        } catch (err) {
            console.log(err);
        }
    };

    const handleReviewClick = async (e, subTopic) => {
        e.stopPropagation();
        const examResponse = examStatus[subTopic];
        if (examResponse) {
            const { examClassData, historyData } = examResponse;
            const examClass = examClassData.examClass;
            const examType = examClassData.examType;
            setLoading(true);
            const questionData = await getExamQuestions(examClass);

            let selectedAnswers = [];

            const wrongAnswers = historyData.wrongQuestionAnswers.sort((a, b) => a.wrongQuestionId.localeCompare(b.wrongQuestionId));

            const wrongAnswersMap = new Map();
            wrongAnswers.forEach(wrongAnswer => {
                wrongAnswersMap.set(wrongAnswer.wrongQuestionId, wrongAnswer.wrongAnswerId);
            });

            questionData.forEach(question => {
                const questionId = String(question.questionData.id);
                const correctAnswer = question.questionData.correctSelection;

                if (wrongAnswersMap.has(questionId)) {
                    selectedAnswers.push(wrongAnswersMap.get(questionId));
                } else {
                    selectedAnswers.push(correctAnswer);
                }
            });

            navigate('/examReview', { state: { questions: questionData, selectedAnswers, topic: examType, subTopic: examClass } });
            setLoading(false);
        }
    };

    const handleResetClick = (e, subTopic) => {
        e.stopPropagation();
        setResetSubTopic(subTopic);
        setConfirmModalIsOpen(true);
    };

    const confirmReset = async () => {
        setDeleting(true);

        try {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const userEmail = user.email;
            await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/history/deleteExamHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ userEmail, topic: 'PAT', subTopic: resetSubTopic }),
            });
        } catch (err) {
            console.log('Failed to reset exam history:', err);
        }

        setExamStatus((prevStatus) => {
            const updatedStatus = { ...prevStatus };
            delete updatedStatus[resetSubTopic];
            return updatedStatus;
        });

        setDeleting(false);
        setConfirmModalIsOpen(false);
    };

    const handleSelectionClick = () => {
        setSubTopics([
            "Mock Exam 1", "Mock Exam 2", "Mock Exam 3", "Mock Exam 4",
            "Mock Exam 5", "Mock Exam 6", "Mock Exam 7", "Mock Exam 8",
            "Mock Exam 9", "Mock Exam 10", "Mock Exam 11", "Mock Exam 12",
            "Mock Exam 13", "Mock Exam 14", "Mock Exam 15", "Mock Exam 16"
        ]);
        setModalIsOpen(true);
    };

    const handleSubTopicClick = (subTopic) => {
        if (patStatus !== 'active' && (subTopic !== "Mock Exam 1")){
            setRemindPurchaseModalIsOpen(true);
        } else {
            setCurrentSubTopic(subTopic);
            setExamTypeModalIsOpen(true); // Open the modal for selecting exam type
        }
    };

    const handleExamTypeSelection = async (examType) => {
        setExamTypeModalIsOpen(false);
        setLoading(true);
        const questionData = await getExamQuestions(currentSubTopic);
        setLoading(false);
        sessionStorage.removeItem('currentQuestionIndex');
        sessionStorage.removeItem('selectedAnswers');
        sessionStorage.removeItem('timeRemaining');
        sessionStorage.removeItem('questions');
        sessionStorage.removeItem('questionStatuses');
        sessionStorage.removeItem('flaggedQuestions');
        navigate(`/exam`, { state: { topic: "PAT", subTopic: currentSubTopic, examType, questionInfo: questionData } });
    };

    const getExamQuestions = async (subTopic) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/question/getExamQuestions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ topic: "PAT", subTopic }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch questions');
            }
            const questionData = await response.json();
            return questionData;
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="practice-page-container">
            {loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <PATIntroduction />
                    <ExamCard
                        onClick={handleSelectionClick}
                        cardClass="card-pat"
                        cardTime="2 hours"
                        cardTitle="开始PAT模考"
                        cardDescription="通过参加完整的 PAT 考试来测试你的知识水平。"
                    />

                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)}
                        contentLabel="Selection Modal"
                        className="Modal"
                    >
                        <h3>PAT 模考试卷</h3>
                        <ul className="exam-list">
                            {subTopics.map((subTopic, index) => (
                                <li 
                                    key={index} 
                                    onClick={() => handleSubTopicClick(subTopic)}
                                    className={`exam-item ${examStatus[subTopic] ? 'done' : 'undone'}`}
                                >
                                    <span className="exam-name">{subTopic}</span>
                                    <div className="exam-status-container">
                                        {patStatus !== 'active' && (subTopic !== "Mock Exam 1") && (
                                            <div className="lock-message">
                                                <span className="unlock-text">解锁访问</span>
                                                <i className="fas fa-lock"></i>
                                            </div>
                                        )}
                                        {examStatus[subTopic] && (
                                            <>
                                                <button 
                                                    className="review-button"
                                                    onClick={(e) => handleReviewClick(e, subTopic)}
                                                >
                                                    考试回顾
                                                </button>
                                                <button 
                                                    className="reset-button"
                                                    onClick={(e) => handleResetClick(e, subTopic)}
                                                >
                                                    考试重置
                                                </button>
                                            </>
                                        )}
                                        <span className={`exam-status ${examStatus[subTopic] ? 'done' : 'undone'}`}>
                                            {examStatus[subTopic] ? '完成' : '未完成'}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Modal>

                    <Modal
                        isOpen={examTypeModalIsOpen}
                        onRequestClose={() => setExamTypeModalIsOpen(false)}
                        contentLabel="Exam Type Selection Modal"
                        className="Modal exam-type"
                        overlayClassName="Overlay"

                    >
                        <>
                            <h3>选择考试形式</h3>
                            <div className="confirm-modal-buttons">
                                <button className="confirm-button" onClick={() => handleExamTypeSelection('timed')}>
                                    计时考试
                                </button>
                                <button className="confirm-button" onClick={() => handleExamTypeSelection('practice')}>
                                    练习考试
                                </button>
                            </div>
                        </>
                    </Modal>

                    <ConfirmResetModal
                        isOpen={confirmModalIsOpen}
                        onRequestClose={() => setConfirmModalIsOpen(false)}
                        resetItem={resetSubTopic}
                        confirmReset={confirmReset}
                        deleting={deleting}
                    />

                    <RemindPurchaseModal
                        isOpen={remindPurchaseModalIsOpen}
                        onRequestClose={() => setRemindPurchaseModalIsOpen(false)}
                        examName={'PAT'}
                    />
                    <FAQ questions={faqQeustions} answers={faqAnswers} />
                    <ContactInfo />
                </>
            )}
        </div>
    );
}

export default PATExam;
