import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import '../styles/RemindPurchaseModal.css';

function RemindPurchaseModal({ isOpen, onRequestClose, examName }) {
    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        onRequestClose(); // Close the modal
        navigate('/service-info', { state: { examName } }); // Redirect to the service info page
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';  // Disable page scrolling
        } else {
            document.body.style.overflow = 'auto';  // Re-enable scrolling when modal closes
        }

        return () => {
            document.body.style.overflow = 'auto';  // Cleanup: ensure scroll is restored
        };
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Remind Purchase Modal"
            className="remind-purchase-modal"
            overlayClassName="remind-purchase-overlay"
        >
            <div className="modal-header">
                <h2>解锁所有模考题目！</h2>
            </div>
            <div className="modal-content">
                <p>若要访问更多模考，请升级您的订阅。解锁所有考试，为 {examName} 考试做好全面的准备！</p>
                <div className="modal-actions">
                    <button className="action-button upgrade-button" onClick={handleUpgradeClick}>马上解锁</button>
                    <button className="action-button cancel-button" onClick={onRequestClose}>等一会</button>
                </div>
            </div>
        </Modal>
    );
}

export default RemindPurchaseModal;
