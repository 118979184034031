export const calculateScoreForMath1Part = (accuracy) => {
    if (accuracy <= 4) {
        return 1;
    }
    if (accuracy <= 24) {
        return (1 + (accuracy - 4) * 8 / 20).toFixed(1);
    }

    if (accuracy >= 25) {
        return 9;
    }
};
  

export const calculateScoreForMath2Part = (accuracy) => {
    if (accuracy <= 4) {
        return 1;
    }
    if (accuracy <= 22) {
        return (1 + (accuracy - 4) * 8 / 18).toFixed(1);
    }

    if (accuracy > 22) {
        return 9;
    }
};

export const calculateScoreForPhysicsPart = (accuracy) => {
    if (accuracy <= 4) {
        return 1;
    }
    if (accuracy <= 22) {
        return (1 + (accuracy - 4) * 8 / 18).toFixed(1);
    }

    if (accuracy > 22) {
        return 9;
    }
};

export const calculateScoreForPAT = (accuracy) => {
    return (accuracy / 40) * 100;
}
  