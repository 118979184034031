import React from 'react';
import '../styles/ESATIntroduction.css';

function ESATIntroduction() {
  return (
    <div className="esat-introduction">
      <div className="intro-section">
        <h2>什么是 ESAT?</h2>
        <hr className="intro-divider" />
        <p>
        从25Fall申请季起，剑桥大学ENGAA和NSAA合并为ESAT（Engineering and Science Admissions Test）。新的ESAT涵盖了原本需要参加ENGAA和NSAA的剑桥大学全部工程与科学类专业，同时，帝国理工学院部分专业也将采用这一统考，以ESAT取代原有的自主命题笔试。
        </p>
        {/* <ul>
          <li>Chemical Engineering and Biotechnology</li>
          <li>Engineering</li>
          <li>Natural Sciences</li>
          <li>Veterinary Medicine</li>
        </ul> */}
        <p>
        在2024-2025申请季，ESAT将安排两次考试： <br></br>

        第一次考试：2024年10月15日至16日   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  报名时间：从2024年8月1日到9月16日。<br></br>

        第二次考试：2025年1月7日至8日  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        报名时间：从2024年10月24日到12月9日。 <br></br>

        如果你申请剑桥大学和帝国理工学院，只能参加10月份的考试， 因为剑桥大学不接受1月份的考试成绩。 <br></br>
        </p>
      </div>
      {/* <div className="intro-section">
        <h2>How To Prepare for the ESAT</h2>
        <hr className="intro-divider" />
        <p>
          Although the ESAT is a new exam, students can still effectively begin their preparations. The focus of the ESAT is on your 
          ability to use and apply your science and mathematics knowledge. It uses science and mathematics that you are likely to 
          have already learned in your school studies, so the best way to prepare is to familiarize yourself with the test format
          and style of questions. The mock exams on this website are designed to closely mimic the real exam situation and difficulty,
          providing you with an authentic practice experience. Each question comes with a detailed, step-by-step explanation to help
          you understand the underlying concepts and improve your problem-solving skills.
        </p>
        <p>
          If you need additional support, please don't hesitate to reach out to us—we're here to help. Our team is committed to your
          success and ready to provide the assistance you need to achieve your goals.
        </p>
      </div> */}
    </div>
  );
}

export default ESATIntroduction;
