import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles/HomePage.css';

function HomePage() {
    return (
        <div className="homepage-container">
            <div className="hero-section">
                <h2>名校申请必看 | 牛津剑桥入学考试模考平台</h2>
                <p>
                    欢迎来到 ExamBee！这里是备考牛津剑桥, 帝国理工工程入学考试的最佳平台。<br />
                    我们已更新至最新版2024年考试形式， 高度还原考试形式和内容。
                </p>
                <div id="exam-choices">
                    <Link to="/EsatExam" className="exam-button esat-button">ESAT</Link>
                    <Link to="/PatExam" className="exam-button pat-button">PAT</Link>
                </div>
            </div>

            {/* Unified Section for "我们提供什么" and "为什么选择 ExamBee" */}
            <div className="unified-section">
                <div className="content-section">
                    <h3>我们提供什么</h3>
                    <ul className="offer-list">
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                            题库包含历年ENGAA真题，NSAA真题，TMUA真题，以及其他相似难度的入学考真题和部分数学物理竞赛题， 总计1000余题！
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                            享受每道题提供的详细分步解析。
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                            获取每次模考的标准化分数，以评估你的表现。
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                            提供不同难度的模拟试卷，帮助你学习、提升技能。
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                            高度还原真实考试的考试界面和考试形式，上考场不紧张！
                        </li>
                    </ul>
                </div>

                <div className="testimonials-section">
                    <h3>为什么选择 ExamBee</h3>
                    <div className="testimonials-container">
                        <div className="testimonial">
                            <p>“今年刚刚改版的入学考试形式让我很难从以前的题目里找到考试的感觉，这个平台让我对考试形式，考试内容有了更清楚的了解。”</p>
                        </div>
                        <div className="testimonial">
                            <p>“ESAT入学考试题目很难在网上找到具体的解析和答案，exambee的模考包含了里面的题目并且给了很具体的解析”</p>
                        </div>
                        <div className="testimonial">
                            <p>“牛剑入学考试大部分内容其实Alevel基本都学过了，但是为了适应考试和一些额外的知识点也需要充足的练习，这个平台契合了我的需求”</p>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <p className="icp-number">
                    ICP备案号: <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">沪ICP备2024091923号-1</a>
                </p>
            </footer>
        </div>
    );
}

export default HomePage;
