import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Latex from 'react-latex-next';
import Modal from 'react-modal';
import 'katex/dist/katex.min.css';
import '../styles/TopicPage.css';

function TopicPage() {
  const { topic } = useParams();
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [questionStatuses, setQuestionStatuses] = useState([]);
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getTopicQuestions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/question/getTopicQuestions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch questions');
      }
      const data = await response.json();
      setQuestions(data);
      setSelectedAnswers(new Array(data.length).fill(null));
      setQuestionStatuses(new Array(data.length).fill('Unseen'));
      setFlaggedQuestions([]);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getTopicQuestions();
  }, [topic]);

  const handleSelectionChange = (index, value) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestionIndex] = value;
    setSelectedAnswers(updatedAnswers);

    const updatedStatuses = [...questionStatuses];
    updatedStatuses[currentQuestionIndex] = 'Complete';
    setQuestionStatuses(updatedStatuses);
  };

  const handleQuestionSelect = (index) => {
    setCurrentQuestionIndex(index);
    setIsModalOpen(false);
    updateQuestionStatus(index);
  };

  const toggleFlag = () => {
    const updatedFlaggedQuestions = [...flaggedQuestions];
    if (updatedFlaggedQuestions.includes(currentQuestionIndex)) {
      const index = updatedFlaggedQuestions.indexOf(currentQuestionIndex);
      updatedFlaggedQuestions.splice(index, 1);
    } else {
      updatedFlaggedQuestions.push(currentQuestionIndex);
    }
    setFlaggedQuestions(updatedFlaggedQuestions);
  };

  const isFlagged = (index) => flaggedQuestions.includes(index);

  const currentQuestion = questions[currentQuestionIndex];

  const updateQuestionStatus = (index) => {
    const updatedStatuses = [...questionStatuses];
    if (updatedStatuses[index] !== 'Complete') {
      updatedStatuses[index] = 'Incomplete';
    }
    setQuestionStatuses(updatedStatuses);
  };

  return (
    <div className={`topic-page-container topic-${topic}`}>
      <h1>Topic: {topic}</h1>
      {error && <p className={`topic-error topic-${topic}`}>{error}</p>}
      <div className="question-header">
        <div className="question-index">
          <span>{currentQuestionIndex + 1} of {questions.length}</span>
        </div>
      </div>
      <div>
        {questions.length > 0 ? (
          <div className="question-container">
            <div className="question-body">
              <p>
                <Latex>{currentQuestion.questionData.questionFront}</Latex>
              </p>
              <p>
                <Latex>{currentQuestion.questionData.questionBody}</Latex>
              </p>
              {currentQuestion.questionData.questionImage && (
                <img
                  className={`question-image topic-${topic}`}
                  src={currentQuestion.questionData.questionImage}
                  alt={`Question ${currentQuestionIndex + 1}`}
                />
              )}
              <ul className={`selections-list topic-${topic}`}>
                {currentQuestion.selections.map((selection, index) => (
                  <li key={index} className={`selection-item topic-${topic}`}>
                    <input
                      type="radio"
                      id={`selection${index}`}
                      name={`selection${currentQuestionIndex}`}
                      value={selection.selectionText}
                      checked={selectedAnswers[currentQuestionIndex] === selection.selectionText}
                      onChange={() => handleSelectionChange(currentQuestionIndex, selection.selectionText)}
                    />
                    <label htmlFor={`selection${index}`}>
                      <Latex>{selection.selectionText}</Latex>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <p className={`no-questions topic-${topic}`}>No questions available for this topic.</p>
        )}
        <div className="navigation-buttons">
          <button onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)} disabled={currentQuestionIndex === 0}>
            Previous
          </button>
          <button onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)} disabled={currentQuestionIndex === questions.length - 1}>
            Next
          </button>
          <button onClick={() => setIsModalOpen(true)}>
            Navigator
          </button>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Question Navigation"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>Navigator - select a question to go to it</h2>
          <table className="modal-table">
            <thead>
              <tr>
                <th>Question #</th>
                <th>Status</th>
                <th>Flagged for Review</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, index) => (
                <tr key={index} onClick={() => handleQuestionSelect(index)} className={index === currentQuestionIndex ? 'selected' : ''}>
                  <td>Question {index + 1}</td>
                  <td>{questionStatuses[index]}</td>
                  <td>{isFlagged(index) ? 'Flagged' : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={() => setIsModalOpen(false)}>Close</button>
        </Modal>
      </div>
    </div>
  );
}

export default TopicPage;
